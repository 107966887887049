import React, {useEffect, useState} from 'react';
import {useAsync} from 'react-use';
import {Button, Grid, Message, Modal} from 'semantic-ui-react';
import {Form} from '../../../forms';
import {checkbox, fieldConfig, masked} from '../../../forms/schema-utils';
import {notifications} from '../../../utils/notification-service';
import {
  IspCustomersService,
  SmsTaggingService,
  TextToTagEnrollmentStatus,
} from '../../../api/generated';
import {FormSpy} from 'react-final-form';
import _ from 'lodash';
import {useHistory} from 'react-router';

type FilterFields = {
  textToTagSmsNumber: any;
  textToTagEnabled: any;
};

const useFields = () => {
  return {
    fields: fieldConfig<FilterFields>({
      textToTagSmsNumber: masked({
        fieldLabel: `This is the mobile number associated with your text to tag enrollment. 
        You can update the number here, but the new number must be unique. Numbers cannot be shared.`,
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      textToTagEnabled: checkbox({
        inputProps: {
          label: 'Enroll in "Text to Tag"?',
        },
      }),
    }),
  };
};

type Error = {
  errorMessage?: string;
  propertyName?: string;
};

type ValidationFailures = {
  errors: Error[];
};

export const TextToTagModal = ({setOpen}) => {
  const history = useHistory();

  useEffect(() => {
    //This runs only on component mount. This clears the route state to open the modal from sales landing.
    history.replace(history.location.pathname, {enrollInTextToTag: false});
  }, [history]);

  const fields = useFields();
  const closeModal = () => {
    setOpen(false);
  };

  const [
    validationFailures,
    setValidationFailures,
  ] = useState<ValidationFailures>({errors: []});

  let validationFailureMessages = _.uniq(
    validationFailures.errors.map((error) => error.errorMessage)
  );

  const fetchCustomer = useAsync(async () => {
    const {result} = await IspCustomersService.getById();
    return result;
  }, []);

  const initialValues = fetchCustomer.value;

  if (initialValues != null && initialValues.textToTagSmsNumber == null) {
    initialValues.textToTagSmsNumber = '';
  }

  const onSubmit = async (values: any) => {
    const enteredNumber = values.textToTagSmsNumber;
    const enteredNumberWithCountryCode = `+1${enteredNumber}`;

    values.phoneNumber = enteredNumberWithCountryCode;

    const response = await SmsTaggingService.enrollToSmsTagging({
      enabled: true,
      phoneNumber: enteredNumberWithCountryCode,
    });

    if (response.hasErrors) {
      setValidationFailures((validationFailures) => {
        return {
          ...validationFailures,
          errors: [...(response.validationFailures ?? [])],
        };
      });
      return;
    }

    notifications.success(
      initialValues?.textToTagStatus ===
        TextToTagEnrollmentStatus.AwaitingConfirmation ||
        initialValues?.textToTagStatus === TextToTagEnrollmentStatus.Enrolled
        ? 'Updated "Text to Tag" information.'
        : initialValues?.textToTagStatus ===
            TextToTagEnrollmentStatus.Unenrolled &&
            'Successfully enrolled in "Text to Tag".'
    );

    closeModal();
  };

  return (
    <>
      <Modal.Header>Text to Tag</Modal.Header>
      <Modal.Content>
        <Message variant="heading4">
          Text to tag is available to all hunters who currently have active
          tags. This feature allows the hunter to validate their tags through
          text messages. If the tag is validated before the animal is moved,
          then you DO NOT have to apply a tag!
          <br />
          {initialValues?.textToTagStatus ===
            TextToTagEnrollmentStatus.Unenrolled &&
            'In order to use Text to Tag, please enter your unique mobile phone number below:'}
          {initialValues?.textToTagStatus ===
            TextToTagEnrollmentStatus.Enrolled && (
            <>
              You are enrolled in text to tag, click{' '}
              <a href="sms://++12252679998">here</a> to start tagging via text
              message.
            </>
          )}
        </Message>

        {validationFailures.errors.length > 0 && (
          <Message error list={validationFailureMessages} />
        )}
        <Message variant="heading3">
          <Form
            initialValues={initialValues}
            className="inline"
            onSubmit={onSubmit}
            render={() => (
              <>
                <Form.Row>
                  <FormSpy>
                    {({values}) => {
                      if (values?.textToTagEnabled === true) {
                        fields.fields.textToTagSmsNumber.fieldRequired = true;
                      } else {
                        fields.fields.textToTagSmsNumber.fieldRequired = false;
                      }
                      return (
                        <Form.InputMasked
                          fieldConfig={fields.fields.textToTagSmsNumber}
                        />
                      );
                    }}
                  </FormSpy>
                </Form.Row>
                <Form.Button
                  type="submit"
                  content={
                    initialValues?.textToTagStatus ===
                    TextToTagEnrollmentStatus.Unenrolled
                      ? 'Enroll'
                      : 'Update Number'
                  }
                  primary
                />

                <Form.Button secondary type="button" onClick={closeModal}>
                  Cancel
                </Form.Button>
              </>
            )}
          />
        </Message>
        {initialValues?.textToTagStatus ===
          TextToTagEnrollmentStatus.AwaitingConfirmation && (
          <Message warning>
            You have asked to enroll to Text to Tag. You should have received a
            text message on the number you entered. Please respond to that
            message to confirm your enrollment. You can change the number you
            wish to use for Text to Tag below.
          </Message>
        )}
        {initialValues?.textToTagStatus ===
          TextToTagEnrollmentStatus.Enrolled && (
          <Message warning>
            <Grid padded>
              Text to tag is provided as part of your license. If you would like
              to unenroll for any reason, please click the 'unenroll' button to
              be unenrolled immediately. You will be able to re-enroll at any
              time.
              <Grid.Row>
                <Button
                  primary
                  onClick={async () => {
                    await SmsTaggingService.enrollToSmsTagging({
                      enabled: false,
                      phoneNumber: initialValues.textToTagSmsNumber,
                    });
                    notifications.success(
                      'Successfully unenrolled in "Text to Tag".'
                    );
                    closeModal();
                  }}
                  content={'Unenroll'}
                />
              </Grid.Row>
            </Grid>
          </Message>
        )}
      </Modal.Content>
    </>
  );
};
